import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

// Connects to data-controller="notice"
export default class extends Controller {
  connect() {
  }


  initialize()
  {
    this.notice_element = this.element
    this.element.setAttribute("data-action", "click->notice#close_notice");
    this.close_timer = null

    if (this.element.classList.contains("alert-success"))
    {
      this.close_timer = setTimeout(() => {this.element.remove()}, 3000)
    }
    else if (this.element.classList.contains("alert-success"))
      {
        this.close_timer = setTimeout(() => {this.element.remove()}, 5000)
      }
  
      // since we will get called after login - make sure the marquee is running

      if (!document.querySelector('.coin-marquee-container'))
      {
        console.log("no marquee")

        window.document.dispatchEvent(new Event("DOMContentLoaded", {
          bubbles: false,
          cancelable: true
        }));

        // this.url = `/marquee`

        // fetch(this.url,
        //   {
        //     headers: {
        //       Accept: "text/vnd.turbo-stream.html"
        //     }
        //   })
        // .then(response => response.text())
        // .then(html => Turbo.renderStreamMessage(html))

      }
  }


  close_notice()
  {
    if (this.close_timer)
    {
      clearTimeout(this.close_timer)
    }
    // @ts-ignore
    this.notice_element.remove()
  }

}
