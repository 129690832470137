import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="fmt"
export default class extends Controller {
  static targets = [ "datetime" ]

  connect() {
    console.log("fmt controller connected")
  }


  // fmt_fields()
  // {
  //   elems = parent.querySelectorAll('[data-local-datetime]');
  //   elems.forEach(function (elem) {
  //     var date = new Date(elem.getAttribute('data-local-datetime'));
  //     var fmt = date.toLocaleDateString() + ' ' + date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
  //     var zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  
  //     elem.innerHTML = fmt;
  //     elem.setAttribute('title', zone);
  //   });
  
  // }

  datetimeTargetConnected(element)
  {
    console.log("connected datetime")
    var date = new Date(element.getAttribute('data-local-datetime'));
    var fmt = date.toLocaleDateString() + ' ' + date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
    var zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    element.innerHTML = fmt;
    element.setAttribute('title', zone);
  }
}
