import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form"
export default class extends Controller {

  connect() {
    console.log("Form controller connected")
  }

  initialize()
  {
    if (this.element.type != "checkbox")
    {
      this.element.setAttribute("data-action", "input->form#update_form");
    }
    else
    {
      this.element.setAttribute("data-action", "change->form#toggle_form_field");
    }
  }

  update_form()
  {
    // Get the class for the controlled sections form the parent

    const target = this.element.parentElement?.getAttribute("data-target")

    const target_class = "." + target

    // Hide all payment section and then show what is needed
    document.querySelectorAll(target_class).forEach(function (element) {
      element.classList.add("d-none")
    })

    const section = this.element.getAttribute("data-button");

    section?.split(" ").forEach(function (c) {
      document.querySelectorAll("." + c).forEach(function (element) {
        element.classList.remove("d-none")
      })
    })
  

  }

  toggle_form_field()
  {
    // Get the class for the controlled sections form the parent

    var target = this.element.getAttribute("data-field")

    var elem = document.getElementById(target)

    elem.classList.toggle("d-none")

    if (elem?.nodeName == "INPUT")
    {
      if (elem.classList.contains('d-none'))
      {
      elem.value = ""
      }
      else
      {
        elem.value = "1.0"
      }
    }
    else
    {
      var div = document.getElementById("xch-rate-div")
      div.classList.toggle("d-none")
    }
  }

}
