import { Controller } from "@hotwired/stimulus"
// import { Dropdown } from "bootstrap"
// import Dropdown from 'bootstrap/js/dist/dropdown';

// Connects to data-controller="menu"
export default class extends Controller {
  connect() {
    console.log("menu controller connected")
    // var dropdownElementList = [].slice.call(document.querySelectorAll('.navbar-nav .dropdown-toggle'))
    // var dropdownList = dropdownElementList.map(function (dropdownToggleEl) {
    //   return new Dropdown(dropdownToggleEl)
    // })

  }
}
