import { Controller } from "@hotwired/stimulus"
import Popover from 'bootstrap/js/dist/popover';

// Connects to data-controller="pops"
export default class extends Controller {
  static targets = ["msg"]
  connect() {
    console.log("pops controller connected")
  }

  msgTargetConnected(element)
  {
    console.log("pops target connected")
    new Popover(element);
  }
}
