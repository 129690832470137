import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

// Connects to data-controller="states"
export default class extends Controller {
  connect() {
    console.log("states controller connected")
  }
  initialize()
  {
    this.element.setAttribute("data-action", "change->states#update_states");
  }

  update_states()
  {
    console.log("Updating states select")
    const country = this.element.options[this.element.selectedIndex].value;
    // const target = JSON.parse(this.element.getAttribute("data-target"));
    const target = this.element.getAttribute("data-target");

    this.url = `/countries/${country}?form_models=${target}`;
    fetch(this.url,
          {
            headers: {
              Accept: "text/vnd.turbo-stream.html"
            }
          })
        .then(response => response.text())
        .then(html => Turbo.renderStreamMessage(html))
  }

}
