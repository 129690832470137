import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="utility"
export default class extends Controller {
  connect() {
    var target = this.element.getAttribute("data-target")

    var elem = document.getElementById(target)

    const c = "table-success"
  
    document.querySelectorAll('tr.' + c).forEach(function (e) {
      e.classList.remove(c);
    });

    elem.classList.add(c)
  }
}
