import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

// Connects to data-controller="remote-modal"
export default class extends Controller {
  static targets = [ "modal" ]
  
  connect() {
    this.modal = new Modal(this.element)
    this.modal.show()
    this.element.addEventListener('hidden.bs.modal', (event) => {
      this.element.remove();
    })
  }

  close_modal()
  {
    Modal.getInstance(this.element);
  }

  // hide modal on successful form submission
  // action: "turbo:submit-end->remote-modal#submitEnd"
  submitEnd(e)
  {
    this.modal.hide()
  }
  
}
