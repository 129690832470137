import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="market"
export default class extends Controller {
  connect() {
    // not used yet
  }

  initialize()
  {
    if (this.element.hasAttribute("data-button"))
    {
      if (this.element.getAttribute("data-button") == "close")
      {
        this.element.setAttribute("data-action", "click->market#close_order_form");
      }
      else if (this.element.getAttribute("data-button") == "limit")
      {
        this.element.setAttribute("data-action", "click->market#show_limit_field");
      }
      else
      {
        this.element.setAttribute("data-action", "click->market#hide_limit_field");
      }
    }
    else
    {
      this.element.setAttribute("data-action", "click->market#prep_form");
    }
  }

  show_limit_field()
  {
    var field = document.getElementById("limit-field-div")
    field.classList.remove("invisible")
  }

  hide_limit_field()
  {
    var field = document.getElementById("limit-field-div")
    field.classList.add("invisible")
  }

  close_order_form()
  {
    document.getElementById('order-form').outerHTML = '<div id="order-form"></div>'
  }
  
  prep_form()
  {
    const user_id = this.element.getAttribute("data-user")
    const order_type = this.element.getAttribute("data-order-type")

    const pair = document.getElementById("currency-pair")
    const currency_1 = pair.getAttribute("data-currency-1");
    const currency_2 = pair.getAttribute("data-currency-2");

    if (currency_1 != currency_2)
    {
      this.url = `/users/${user_id}/init_order?order_type=${order_type}&currency_1=${currency_1}&currency_2=${currency_2}`;

      fetch(this.url,
            {
              headers: {
                Accept: "text/vnd.turbo-stream.html"
              }
            })
          .then(response => response.text())
          .then(html => Turbo.renderStreamMessage(html))
    }
  }
}
