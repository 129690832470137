import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="xch-value"
export default class extends Controller {
  connect() {
    console.log("xch-value connected")
  }


  initialize()
  {
    const method = this.element.getAttribute("data-xch-method");

    this.element.setAttribute("data-action", "change->xch-value#" + method);
  }


  convert_value()
  {
    const currency = this.element.options[this.element.selectedIndex].value;
    const target = this.element.getAttribute("data-target");
    const user = this.element.getAttribute("data-user");
    const account = this.element.getAttribute("data-account");

    const btn = document.getElementById("account_" + account + "-payment-btn")
    const opt = this.element.options[this.element.selectedIndex].innerText;

    const target_element = document.getElementById(target)

    var href = btn.getAttribute('href').replace(/currency=.*$/, "currency=" + opt)

    btn.href = href

    target_element.innerHTML = ""

    this.url = `/users/${user}/accounts/${account}/convert_balance?target_div=${target}&currency_id=${currency}`;
    fetch(this.url,
          {
            headers: {
              Accept: "text/vnd.turbo-stream.html"
            }
          })
        .then(response => response.text())
        .then(html => Turbo.renderStreamMessage(html))
  }

  currency_value()
  {
    const new_currency = this.element.options[this.element.selectedIndex].value;
    const target = this.element.getAttribute("data-target");
    const user = this.element.getAttribute("data-user");
    const currency_id = this.element.getAttribute("data-currency");

    const target_element = document.getElementById(target)

    target_element.innerHTML = ""

    this.url = `/users/${user}/currencies/${currency_id}/convert_balance?target_div=${target}&new_currency_id=${new_currency}`;
    fetch(this.url,
          {
            headers: {
              Accept: "text/vnd.turbo-stream.html"
            }
          })
        .then(response => response.text())
        .then(html => Turbo.renderStreamMessage(html))
  }
}
