import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"
import Popover from 'bootstrap/js/dist/popover';

// Connects to data-controller="op-btns-close"
export default class extends Controller {
  connect() {

  }

  initialize()
  {
    this.element.setAttribute("data-action", "click->op-btns-close#close_op_area");

  }

  close_op_area()
  {
    const user = this.element.getAttribute("data-user");

    document.querySelectorAll('button.popover-btn').forEach(function (e) {
      Popover.getInstance(e).hide()
    });

    this.url = `/users/${user}/op_btns`;
    fetch(this.url,
        {
          headers: {
            Accept: "text/vnd.turbo-stream.html"
          }
        })
      .then(response => response.text())
      .then(html => Turbo.renderStreamMessage(html))
  }

}
