import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

// Connects to data-controller="xch"
export default class extends Controller {
  connect() {

    console.log("xch controller connected")
    this.refresh_timer = setTimeout(this.update_data, 10000)
  }

  update_data()
  {
    // console.log("xch updating")
    if (document.getElementById("order-form-opened"))
    {
      return
    }
    const div_element = document.getElementById("xch-div")
    const user_id = div_element.getAttribute("data-user");

    const pair = document.getElementById("currency-pair")
    const currency_1 = pair.getAttribute("data-currency-1");
    const currency_2 = pair.getAttribute("data-currency-2");

    if (currency_1 != currency_2)
    {
      this.url = `/users/${user_id}/refresh_xch?currency_1=${currency_1}&currency_2=${currency_2}`;

      fetch(this.url,
            {
              headers: {
                Accept: "text/vnd.turbo-stream.html"
              }
            })
          .then(response => response.text())
          .then(html => Turbo.renderStreamMessage(html))
    }

    // this.refresh_timer = setTimeout(() => this.update_data, 10000)
  }

  disconnect()
  {
    if (this.refresh_timer)
    {
      console.log("xchange refresh disconnected")
      clearInterval(this.refresh_timer)
    }
  }
}
